import { MabadiveProWebConfig } from '../model';

const appVersion = process.env.REACT_APP_MABADIVE_TAG;
const cdnUrl = 'https://mabadive.fra1.cdn.digitaloceanspaces.com';

const localIp = '192.168.1.100'; // peut changer

export const mabadiveProWebConfig_DEV_IP: MabadiveProWebConfig = {
  envId: 'dev',
  appVersion,
  applications: {
    publicWebUrl: `http://${localIp}:3600`,
    docsWebUrl: `http://${localIp}:3700`,
    diverWebUrl: `http://${localIp}:3009`,
    cdnUrl,
    cdnUpload: `${cdnUrl}/upload`,
  },
  debug: {
    showEnvId: false,
    isEnvDemoOrTest: false,
    checkNewVersion: false,
    showAppVersion: false,
    showMediaSize: false,
    showErrorStackTrace: false,
    debugStore: false,
    autoFill: true,
  },
  authentication: {
    defaultAuthLogin: {
      login: 'toub',
      // password: '',
    },
  },
  data: {
    api: {
      baseUrl: `http://${localIp}:5000/api`,
      // baseUrl: 'http://192.168.1.10:5000/api',
    },
    graphql: {
      enabled: true,
      // NOTE: docker-host is defined as 0.0.0.0 in /etc/hosts
      hostname: `${localIp}:8080`,
      // hostname: '192.168.1.10:8000',
      context: 'v1/graphql',
      httpProtocol: 'http',
      wsProtocol: 'ws',
    },
  },
  log: {
    level: 'info',
    sentry: {
      enabled: true,
      configString:
        'https://7ae1eb2d24134dbbb7776fc5b8a7c286@o300584.ingest.sentry.io/1724522',
    },
    console: {
      appRules: {
        // console logger levels
        app: {
          level: 'info',
          modules: {
            // 'app-common-browser': 'debug',
            // 'security': 'info',
            // 'patch': 'info',
          },
        },
        'app-common-browser': {
          level: 'debug',
          modules: {
            // 'app-common-browser': 'debug',
            // 'security': 'info',
            // 'patch': 'info',
          },
        },
      },
    },
  },
  c: {
    e: [['contact'], ['mabadive', 'com']],
    p: ['+33', '7', '44', '70', '30', '55'],
  },
};
