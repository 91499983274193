import {
  mabadiveProWebConfig_DEV,
  mabadiveProWebConfig_DEV_IP,
  mabadiveProWebConfig_DIST,
} from './env';
import { getEnvIdFromUrl } from './getEnvIdFromUrl.service';
import { MabadiveProWebConfig } from './model';

const envId = getEnvIdFromUrl();

const isDevIP = process.env.REACT_APP_STAGE === 'dev-ip'; // to debug on local network: http://192.168.1.100:3000 (chercher "localIp =")
const isDev = process.env.REACT_APP_STAGE === 'dev' || envId === 'dev';

export const appWebConfig: MabadiveProWebConfig = isDevIP
  ? mabadiveProWebConfig_DEV_IP
  : isDev
  ? mabadiveProWebConfig_DEV
  : mabadiveProWebConfig_DIST;
